export default {
  init() {
    // JavaScript to be fired on all pages

    // header search form trigger
    let searchForm = $(document).find('header .search-form');
    $('.trigger', searchForm).on('click', function() {
      $(searchForm).toggleClass('active');
    });

    // Slow scroll with anchors
    $('a[href*="#"]').not('[href="#"]').not('[href="#0"]').not('[data-toggle="tab"]').click(function (event) {
      if(!$(this).closest('.wc-tabs' ).length) {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
          if (target.length > 0) {
            event.preventDefault();
            $('html, body').animate({
              scrollTop: target.offset().top
            }, 500);
          }
        }
      }
    });

    // tooltip
    $('[data-toggle="tooltip"]').tooltip()

    // calculate banner height
    function calculateBannerHeight() {
      if ($(window).width() > 991) {
        var $navbarBrand = $(".navbar-brand").outerHeight( true );
        var $navbarInner = $(".navbar-inner").outerHeight( true );
        var $exposed = $(".exposed").outerHeight( true );
        var CalculateNavbar = $navbarBrand + $navbarInner + $exposed + 6;
        var CalculateNavbarAlt = $navbarBrand + $navbarInner + 4;
        var wkbBanner= $(".wkb-banner");
        var wkbBannerAlt= $(".wkb-banner__alt, .page-image-container");

        wkbBanner.css('height', CalculateNavbar);
        wkbBannerAlt.css('height', CalculateNavbarAlt);
      };
    }

    calculateBannerHeight()

    $(window).on('resize', function(){
      calculateBannerHeight()
    });

    //Back to top button
    let toTopButton = document.getElementById("btn-back-to-top");
    
    // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function () {
      scrollFunction();
    };

    function scrollFunction() {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        toTopButton.style.display = "block";
      } else {
        toTopButton.style.display = "none";
      }
    }
    // When the user clicks on the button, scroll to the top of the document
    toTopButton.addEventListener("click", backToTop);

    function backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }

    // Animations with animate.css
    function callbackFunc(entries, observer) {
      entries.forEach(entry => {
        let $e = $(entry.target);
        if(entry.isIntersecting) {
          let $animation_e = $e;
          let animation = $animation_e.data('animation');
          
          setTimeout(function() {
            $e.addClass('animate_start ' + animation);
          }, 400);
        }
      });
    }
    
    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.2
    };
    
    let observer = new IntersectionObserver(callbackFunc, options);
    let $animation_elements = $('.animate__animated');

    $animation_elements.each(function( e ) {
      observer.observe($(this)[0]);
    });

    $('select').select2({
      width: 185,
      minimumResultsForSearch: -1,
      // dropdownParent: $('.select2-container'),
      //dropdownPosition: 'below'
    });   
    
    // $('select').on('select2:open', function(e) {
    //   $(e.delegateTarget)
    //     .parent()
    //     .addClass('active');
    // });

    // $('select').on('select2:close', function(e) {
    //   $(e.delegateTarget)
    //     .parent()
    //     .removeClass('active');
    // });


    var reinitTimer;

    //CalculateRihtSide();

    $(window).resize(function() {
      clearTimeout(reinitTimer);
      //CalculateRihtSide();
      //reinitTimer = setTimeout(CalculateRihtSide, 100);
      if ($('.navbar.mobile').hasClass('active')) {
        $('.navbar.mobile .navbar-toggler').click();
      }
    });

    function CalculateRihtSide() {
      $('body .right-side').width($('.page-container').width() - 257);
    }

    $('.navbar.mobile .navbar-toggler').on('click', function() {
      if ($(this).attr('aria-expanded') == 'false') {
        $('.navbar.mobile').addClass('active');

        $('body').css("overflow","hidden");
        $('.navbar').css("overflow","scroll").css("overflow-x","hidden");
        $('.navbar-toggler').css("position","fixed");
      } else {
        $('.navbar.mobile').removeClass('active');
        
        $('body').css("overflow","visible");
        $('.navbar').css("overflow","auto");
        $('.navbar-toggler').css("position","relative");
      }
    });

    $( ".select-language" ).on( "change", function() {
      var langChoice = $( "#lang_choice_1" ).val();
      window.location = langChoice;
    });
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};
