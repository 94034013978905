$('select').select2({
  minimumResultsForSearch: -1,
  placeholder: function(){
      $(this).data('placeholder');
  }
});

$( "select" ).on( "change", function() {
  var selectTarget = $( "#location-select" ).val();

  $('.wkb-location-map__search-sights').removeClass('d-block');
  $('.wkb-location-map__search-description').removeClass('d-block');
  $('[id="'+selectTarget+'"]').addClass('d-block');
});