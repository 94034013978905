var $wkb_posts = $(document).find('.wkb-posts');
if( $wkb_posts.length > 0 ) {
  $.each($wkb_posts, function( index, posts ) {
    var $posts = $(posts);
    var $slides = $('.owl-carousel', $posts);
    if( $slides.length > 0 ) {
      var owl = $slides.owlCarousel({
        items: 1,
        slideBy: 1,
        dots: true,
        dotsContainer: '#' + $posts.find('.owl-dots').prop('id'),
        nav: false,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        margin: 30,
        responsive : {
          // breakpoint from 480 up
          320 : {
            items: 1,
          },
          // breakpoint from 768 up
          768 : {
            items: 2,
          },
          // breakpoint from 768 up
          1024 : {
            items: 3,
          }
        }
      });
      $('.owl-dot', $posts).on('click', function () {
        owl.trigger('to.owl.carousel', [$(this).index(), 300]);
      });
    }

  });
}
