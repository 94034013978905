import { HELPERS } from '../classes/helpers';

let ofAge = $('.of-age-container');

setTimeout(() => {
    let cookie = HELPERS.readCookie('of-age');
    if (cookie === null) {
        ofAge.addClass('active');
    }
}, 400);

ofAge.find('.yes').on('click', () => {
    HELPERS.createCookie('of-age', 1, 21);
    ofAge.removeClass('active');
});

ofAge.find('.no').on('click', () => {
    window.history.back();
});