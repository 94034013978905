var mapbox = $(document).find('.wkb-location-map');

if(mapbox.length > 0) {
  var buttons = mapbox.find('button');
  var marker_brewery = mapbox.data('marker');
  var marker_sight = mapbox.data('marker-sight');
  var accessToken = mapbox.data('accesstoken');
  var fairLocation = mapbox.data('location');
  var map = null;
  var allMarkers = [];
  var trainRoute = null;
  var mapInit = false;

  // init mapbox if user has element in view
  function callbackFunc(entries, observer) {
    entries.forEach(entry => {
      let $e = $(entry.target);
      if(entry.isIntersecting && !mapInit) {
        init_mapBox();
        mapInit = true;
      }
    });
  }
  
  let options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.5
  };

  let observer = new IntersectionObserver(callbackFunc, options);
  mapbox.each(function( e ) {
    observer.observe($(this)[0]);
  });


  function init_mapBox() {
    if(typeof mapboxgl !== undefined) {
      mapboxgl.accessToken = accessToken;
  
      // addGmapLinks();
      
      map = new mapboxgl.Map({
        container: 'mapbox-map',
        zoom: '7.5',
        center: [fairLocation['lon'], fairLocation['lat']],
        style: 'mapbox://styles/mapbox/light-v11',
        cooperativeGestures: true
      });
  
      $('#location-select').on('change', function() {
        let $this = $(this);
        removeMarkers();

        $.ajax({
          url: ajax.url,
          type: 'post',
          dataType: "json",
          data: {
            action: 'get_locations',
            post_id: $this.val(),
          },

          success: function (result) {
            addMarkers(result.locations);
            fitBounds(result.locations);
          },

          error: function(xhr, ajaxOptions, thrownError) {
            console.log(thrownError);
          }
        });
      });
    }
  }

  function addMarkers(locations, primary = false) {
    if(location) {
      locations.forEach(location => {
        var markerPopup = new mapboxgl.Popup({ offset: {
            'top': [0, 0], 
            'bottom': [0, -30], 
            'left': [15, -15],
            'right': [-15, -15]} })
          .setHTML(location['content']);

        var customMarker = document.createElement('div');
        customMarker.style.backgroundImage = 'url("' + (location['brewery'] == true ? marker_brewery : marker_sight) + '")';
        customMarker.style.backgroundSize = 'contain';
        customMarker.style.backgroundPosition = 'center';
        customMarker.style.backgroundRepeat = 'no-repeat';
        customMarker.style.width = (location['brewery'] == true ? '40px' : '30px');
        customMarker.style.height = (location['brewery'] == true ? '40px' : '30px');
        customMarker.style.zIndex = (location['brewery'] == true ? '1' : '');
  
        var marker = new mapboxgl.Marker({ element: customMarker, offset: [0, -15] })
          .setLngLat([location['lon'], location['lat']])
          .setPopup(markerPopup)
          .addTo(map);

          if(!primary)
            allMarkers.push(marker);
          else
            marker.togglePopup();
      });
    }
  }

  function removeMarkers() {
    if (allMarkers.length > 0) {
      
      allMarkers.forEach(marker => {
        marker.remove();
      });

      if (map.getSource('route')) {
        map.removeLayer('route');
        map.removeSource('route');
      }
      allMarkers = [];
    }
  }

  function fitBounds(fitBoundsLocations) {
   
    // Create a 'LngLatBounds' with both corners at the first coordinate.
    const bounds = new mapboxgl.LngLatBounds(
      allMarkers[0].getLngLat(),
      allMarkers[0].getLngLat()
    );
    
    // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
    allMarkers.forEach(coord => {
      bounds.extend(coord.getLngLat());
    });
    
    const point = map.project([fitBoundsLocations[0]['lon'], fitBoundsLocations[0]['lat']]);
    map.fitBounds(bounds, {
      padding: 100,
    });

  }

  function getRoute(start, end, route, callback) {
    if(!route) {
      $.get( 'https://api.mapbox.com/directions/v5/mapbox/walking/' + start.lon + ',' + start.lat + ';' + end.lon + ',' + end.lat + '?steps=true&geometries=geojson&access_token=' + accessToken + '', function(data) {
        route = data.routes[0].geometry.coordinates;
        drawRoute(route);
        callback(route);
      });
    } else {
      drawRoute(route);
      callback(route);
    }
  }

  function drawRoute(route) {
    const geojson = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: route
      }
    };

    if (map.getSource('route')) {
      map.getSource('route').setData(geojson);
    } else {
      map.addLayer({
        id: 'route',
        type: 'line',
        source: {
          type: 'geojson',
          data: geojson
        },
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#662878',
          'line-width': 5,
          'line-opacity': 0.75
        }
      });
    }
  }
}
